export default function getErrors(error) {
  const hasBody = error.response && error.response.data;
  const rendimentoError = error?.response?.data?.message?.error?.code;

  if (rendimentoError) {
    return error?.response?.data?.message?.error?.message[0]
      .message?.split('Erro: ')[1] || 'Houve um erro no processamento do envio da TED';
  }

  if (hasBody && error.response.data.error === 'Bad Request') {
    if (error.response.data.validation.body) {
      return error.response.data.validation.body.message;
    }

    if (error.response.data.validation.query) {
      return error.response.data.validation.query.message;
    }

    return 'Erro na validação dos parâmetros.';
  }

  if (hasBody && error.response.data.message) {
    return error.response.data.message;
  }

  if (hasBody && error.response.data === 'Too Many Requests') {
    return 'Muitas solicitações, agurde alguns segundos e tente novamente.';
  }

  return 'Erro desconhecido.';
}
