/* eslint-disable react/jsx-no-bind */
import React, {
  useEffect, useCallback, useRef, useState,
} from 'react';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Table, Dropdown, Menu, Modal, Skeleton, notification, Checkbox,
} from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import { FaTrashAlt, FaEllipsisH } from 'react-icons/fa';

import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';

import formErrors from '../../../services/formErrors';
import getErrors from '../../../services/errors';

import { Row, Col } from '../../../styles/components';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';

import Password from '../../../components/Password';
import Notification from '../../../components/Notification';
import RadioButtons from '../../../components/Radio';

import api from '../../../services/api';
import { useSettings } from '../../../hooks/settings';
import { documentMask } from '../../../services/utils';
import {
  Content, ContentTab, MyAccounts, Account, ModalHeader,
} from './styles';
import { formatAccount } from '../../Transaction/Receipt/utils';

const { Column } = Table;

function Ted() {
  const formRef = useRef(null);
  const { user } = useAuth();
  const { settings } = useSettings();
  const [banks, setBanks] = useState([]);
  const [favorities, setFavorities] = useState([]);
  const [loadFetchFavorities, setLoadFetchFavorities] = useState([]);
  const [myAccounts, setMyAccounts] = useState([]);
  const [transfer, setTransfer] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [amount, setAmount] = useState(null);
  const [dataTransfer, setDataTransfer] = useState({});
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [contact, setContact] = useState(false);
  const [document, setDocument] = useState('');
  const [save, setSave] = useState(false);
  const [outOfTimeInfo, setOutOfTimeInfo] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  const [defaultValueTypeAccount, setDefaultValueTypeAccount] = useState('CC');

  const loadBanks = useCallback(() => {
    api.get('/banks').then(({ data }) => {
      const { banks: b = [] } = data;
      setBanks(b.map((bank) => ({ value: bank.code, label: `${bank.code} - ${bank.name}` })));
    });
  }, []);

  const loadFavorities = useCallback(() => {
    setLoadFetchFavorities(true);
    api.get('/favorites?filter=TED').then(({ data }) => {
      setFavorities(data);
      setMyAccounts(data.filter((fav) => fav.document === user.document));
      setLoadFetchFavorities(false);
    }).catch(() => {
      setLoadFetchFavorities(false);
    });
  }, [user]);

  useEffect(() => {
    loadBanks();
    loadFavorities();
  }, [loadBanks, loadFavorities]);

  const getNameByDocument = useCallback(async (value) => {
    setDocument(value);

    if (value.length === 11 || value.length === 14 || value.length === 18) {
      const formattedDocument = value.replace(/[^0-9]/g, '');

      const { data: userData } = await api.get(`api-id/${formattedDocument}`, {
        headers: {
          client: settings.client_id,
        },
      });

      formRef.current.setFieldValue('beneficiary.name', value.replace(/[^0-9]/g, '')
        .length === 11 ? userData.name : userData.company_name);
    }
  }, [setDocument, settings, formRef]);

  const getNameBankByCode = useCallback((code) => {
    const bank = banks.find((b) => b.value === Number(code));
    if (bank) {
      return bank.label;
    }

    return code;
  }, [banks]);

  async function handleSubmit(data) {
    formRef.current.setErrors({});

    try {
      if (!amount || amount === 0) {
        formRef.current.setErrors({
          amount: t('o valor e obrigatorio'),
        });
        return;
      }

      const schema = Yup.object().shape({
        beneficiary: Yup.object().shape({
          document: Yup.string()
            .required(t('o documento é obrigatório.'))
            .matches(
              /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/,
              t('o documento deve ter 11 ou 14 digitos'),
            ),
          name: Yup.string()
            .max(100, t('o nome deve ter, no maximo, 100 caracteres'))
            .required(t('o nome e obrigatorio')),
          bank: Yup.string().required(t('o banco e obrigatorio')),
          agency: Yup.string()
            .length(4, t('a agencia precisa ter 4 digitos'))
            .matches(/^\d+$/, t('apenas numeros sao aceitos'))
            .required(t('a agencia e obrigatoria')),
          account: Yup.string()
            .matches(/^\d+$/, t('apenas numeros sao aceitos'))
            .required(t('a conta e obrigatoria')),
          digit: Yup.string()
            .matches(/^\d+$/, t('apenas numeros sao aceitos'))
            .required(t('o digito e obrigatorio')),
          type: Yup.string().required(),
        }).required(),
      }).required();

      await schema.validate(data, {
        abortEarly: false,
      });

      setShowPassword(true);
      setDataTransfer({ ...data, amount });
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
      }
    }
  }

  const handleSchedule = useCallback(async () => {
    try {
      await api.post('transactions/scheduled', {
        beneficiary: {
          name: dataTransfer.beneficiary.name,
          document: dataTransfer.beneficiary.document,
          bank: String(dataTransfer.beneficiary.bank),
          agency: dataTransfer.beneficiary.agency,
          account: `${dataTransfer.beneficiary.account}${dataTransfer.beneficiary.digit}`,
          type: dataTransfer.beneficiary.type,
        },
        amount,
        password: outOfTimeInfo.password,
      });
      notification.success({ message: t('sua transferencia foi agendada com sucesso') });
      history.push('/transaction');
    } catch (err) {
      setError(getErrors(err));
    }
    setOutOfTimeInfo(null);
  }, [amount, dataTransfer, outOfTimeInfo, history, t]);

  async function handleTransfer(password) {
    setLoadingTransfer(true);
    try {
      await api.post('transactions', {
        beneficiary: {
          name: dataTransfer.beneficiary.name,
          document: dataTransfer.beneficiary.document,
          bank: String(dataTransfer.beneficiary.bank),
          agency: dataTransfer.beneficiary.agency,
          account: `${dataTransfer.beneficiary.account}${dataTransfer.beneficiary.digit}`,
          type: dataTransfer.beneficiary.type,
        },
        amount,
        password,
        save,
      });

      setDefaultValueTypeAccount('CC');

      setLoadingTransfer(false);
      setAmount(0);
      setTransfer(false);
      setContact(false);
      setDataTransfer({});
      setSuccess(t('transferencia realizada com sucesso'));
    } catch (err) {
      console.log('Erro na transferência: ', err?.response?.data);
      const errorMessage = getErrors(err);
      // fora do horario ou feriado
      if (errorMessage?.includes(t('operacao permitida'))) {
        setOutOfTimeInfo({ message: errorMessage, password });
      } else {
        setError(errorMessage);
      }
      setLoadingTransfer(false);
    }
  }

  const changeValue = useCallback((event, maskedvalue, floatvalue) => {
    setAmount(floatvalue);
  }, []);

  const handleDeleteContact = useCallback((_selectContact) => {
    Modal.confirm({
      title: t('excluir favorito'),
      icon: <ExclamationCircleOutlined />,
      content: t('tem certeza que deseja excluir esse favorito'),
      okText: t('sim'),
      cancelText: t('nao'),
      centered: true,
      onOk: async () => {
        try {
          await api.delete(`/favorites/${_selectContact.id}`);
          loadFavorities();
          notification.success({
            message: t('favorito removido'),
            description:
              t('favorito removido com sucesso'),
          });
        } catch (err) {
          setLoadingTransfer(false);
          setError(getErrors(err));
        }
      },
    });
  }, [loadFavorities, t]);

  const handleOptionsFavorities = useCallback(async (e, selectContact) => {
    if (e.key === '1') {
      setTransfer(true);
      setContact(selectContact);
      return;
    }

    handleDeleteContact(selectContact);
  }, [handleDeleteContact]);

  useEffect(() => {
    if (transfer && contact) {
      formRef.current.setData({
        beneficiary: {
          document: contact.document,
          name: contact.name,
          bank: { value: contact.bank, label: getNameBankByCode(contact.bank) },
          agency: contact.agency,
          account: contact.account.substr(0, contact.account.length - 1),
          digit: contact.account[contact.account.length - 1],
          type: contact.type,
        },
      });

      setDefaultValueTypeAccount(contact.type);
    }
  }, [transfer, contact, getNameBankByCode]);

  if (settings && settings.name) {
    return (
      <>
        <h1>TED</h1>
        <Loading hasLoading={loadingTransfer} />
        <Notification
          type="error"
          title={t('erro na transferencia')}
          visible={!!error}
          hidden={() => setError(false)}
        >
          {error}
        </Notification>
        <Notification
          title={t('transferencia efetuada')}
          visible={!!success}
          hidden={() => setSuccess(false)}
        >
          {success}
        </Notification>
        <Modal
          wrapClassName="modal-password"
          visible={outOfTimeInfo}
          centered
          footer={false}
          closable={false}
          style={{ padding: 0, margin: 0, borderRadius: '8px' }}
        >
          <ModalHeader backgroundcolor={settings.colors.primary}>{t('agendar transferencia')}</ModalHeader>
          <p style={{ marginTop: 30 }}>{outOfTimeInfo?.message}</p>
          <p>{t('deseja agendar essa transferencia para o proximo dia util')}</p>
          <div style={{ height: 40 }}>
            <Button onClick={handleSchedule} right>{t('agendar')}</Button>
            <Button
              onClick={() => setOutOfTimeInfo(null)}
              style={{ marginRight: 10 }}
              backgroundcolor="rgba(0,0,0,0.4)"
              shadowcolor="rgba(0,0,0,0.4)"
              right
            >{t('nao agendar')}
            </Button>
          </div>
        </Modal>
        <Content>
          <Password
            visible={showPassword}
            hidden={() => setShowPassword(false)}
            afterPassword={handleTransfer}
          />
          {!transfer ? (
            <>
              <Button type="button" right={(false)} onClick={() => setTransfer(true)}>
                <span>Fazer nova TED</span>
              </Button>

              {favorities.length > 0 && (
                <>
                  <h3>{t('minhas contas')}</h3>
                  <MyAccounts>
                    {myAccounts.map((favorite) => (
                      <Account color={settings.colors.primary} key={favorite.id}>
                        <span
                          onClick={() => {
                            setTransfer(true);
                            setContact(favorite);
                          }}
                        >{getNameBankByCode(favorite.bank)} / {favorite.agency} / {formatAccount(favorite.account)}
                        </span>
                        <span
                          onClick={() => handleDeleteContact(favorite)}
                        ><FaTrashAlt color={settings.colors.primary} />{t('excluir')}
                        </span>
                      </Account>
                    ))}
                  </MyAccounts>
                </>
              )}

              <ContentTab>
                <h3>{t('favoritos')}</h3>
                {loadFetchFavorities ? (
                  <>
                    <Skeleton />
                  </>
                ) : (
                  <Table
                    dataSource={favorities}
                    pagination={false}
                    rowKey="id"
                    loading={false}
                    locale={{
                      emptyText: t('nenhum favorito para exibir'),
                    }}
                    scroll={{ x: true }}
                    style={{ height: '400px', overflow: 'scroll', display: 'nowrap' }}
                  >
                    <Column
                      title={t('nome')}
                      dataIndex="name"
                      key="name"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />
                    <Column
                      title={t('banco')}
                      dataIndex="bank"
                      key="bank"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text) => (
                        <>
                          {getNameBankByCode(text)}
                        </>
                      )}
                    />
                    <Column
                      title={t('agencia')}
                      dataIndex="agency"
                      key="agency"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />
                    <Column
                      title={t('conta')}
                      dataIndex="account"
                      key="account"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />
                    <Column
                      title={t('acoes')}
                      align="center"
                      dataIndex="name"
                      key="name"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(_text, record) => (
                        <Dropdown
                          trigger={['click']}
                          overlay={(
                            <Menu onClick={(e) => handleOptionsFavorities(e, record)}>
                              <Menu.Item key="1">{t('transferir para este contato')}</Menu.Item>
                              <Menu.Item key="2">
                                <span>{t('excluir favorito')}</span>
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <FaEllipsisH style={{ cursor: 'pointer' }} />
                        </Dropdown>

                      )}
                    />
                  </Table>

                )}
              </ContentTab>
            </>
          ) : (
            <>
              {/* <h2>Horário limite para TED operação:</h2>
            <p>Segunda a sexta-feira, de 06:30h às 16:25h.
              Após esse horário limite, as transferências são agendados automaticamente
              para o próximo dia útil.
            </p> */}
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{ amount: 1 }}
                style={{ marginTop: '32px' }}
              >
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                    <Input
                      disabled={!!contact}
                      id="beneficiary.document"
                      name="beneficiary.document"
                      label={t('cpf / cnpj')}
                      type="text"
                      // showMask={false}
                      // placeholderChar=" "
                      onChange={(e) => getNameByDocument(e.target.value)}
                      value={(contact ? documentMask(contact.document) : documentMask(document))}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Input
                      disabled={!!contact}
                      id="beneficiary.name"
                      name="beneficiary.name"
                      label={t('nome completo')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                    <Input
                      select
                      isDisabled={!!contact}
                      id="beneficiary.bank"
                      name="beneficiary.bank"
                      label={t('banco')}
                      options={banks}
                      type="text"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Input
                      disabled={!!contact}
                      id="beneficiary.agency"
                      name="beneficiary.agency"
                      maxLength="4"
                      label={t('agencia')}
                      type="text"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Input
                      disabled={!!contact}
                      id="beneficiary.account"
                      name="beneficiary.account"
                      label={t('conta')}
                      type="text"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Input
                      disabled={!!contact}
                      id="beneficiary.digit"
                      name="beneficiary.digit"
                      maxLength="1"
                      label={t('digito')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                    <Input
                      mask="currency"
                      onChangeEvent={changeValue}
                      id="amount"
                      name="amount"
                      value={amount}
                      label={t('valor')}
                      type="text"
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{ display: 'flex', alignItems: 'center' }}
                    className="bottom"
                  >
                    <RadioButtons
                      name="beneficiary.type"
                      radios={[{
                        color: settings.colors.primary,
                        value: 'CC',
                        label: t('conta corrente'),
                      },
                      {
                        color: settings.colors.primary,
                        value: 'PP',
                        label: t('conta poupanca'),
                      },
                      ]}
                      defaultChecked={defaultValueTypeAccount}
                    />
                  </Col>
                </Row>
                {!contact && (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                    <Checkbox
                      checked={save}
                      disabled={contact}
                      style={{ fontSize: '16px' }}
                      onChange={(e) => {
                        setSave(e.target.checked);
                      }}
                    >{t('adicional aos favoritos')}
                    </Checkbox>
                  </Col>
                )}

                <Row gutter={16}>
                  <Col md={24}>
                    <Button type="submit">
                      <span>{t('transferir')}</span>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}

        </Content>
      </>
    );
  }
  return <></>;
}

export default Ted;
