/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message as messageAntd } from 'antd';
import InputN from '../../../../../components/InputN';
import ButtonN from '../../../../../components/ButtonN';
import LowerLeftMessage from '../../../../../components/LowerLeftMessage';
import Alert from '../../../../../components/SpanError';
import messages from '../../../../../components/MessageError';
import { DualButtonsDisposition, Title } from './styles';
import { useSteps } from '../../../../../hooks/steps';
import { useSettings } from '../../../../../hooks/settings';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import ModalForTransition from '../../../../../components/ModalForTransition/index';
import api from '../../../../../services/api';
import { emailRegex } from '../../../../../utilitys/Masks/emailRegex';

const Email = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const {
    nextStep, prevStep, step, exactStep, stepsPf,
  } = useSteps();
  const { settings } = useSettings();
  const history = useHistory();

  const [confEmail, setConfEmail] = useState({ emailClient: '' });
  const [loading, setLoading] = useState('');
  const [emailValido, setEmailValido] = useState(false);
  const [emailConfirm, setEmailConfirm] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [, setTypeError] = useState('');
  const [userEmail, setUserEmail] = useState({email:''});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const validEmail = !userEmail.email  || emailRegex.test(userEmail?.email)
  const validRepeatedEmail = confEmail?.emailClient === '' || confEmail?.emailClient === userEmail?.email

  useEffect(() => {
    if (checkpoint?.cellphoneConfirmationCode !== undefined) {
      exactStep(4);
    } else {
      exactStep(2);
      history.replace('/register/cellphone');
    }
  }, []);

  useEffect(() => {
    setUserEmail('');
  }, []);

  const handleVerificacao = () => {
    setShowConfirmationModal(true);
  };

  const handleAdvance = (numberOfSteps) => {
    setCheckpoint({ ...checkpoint, email: userEmail.email });
    nextStep(numberOfSteps);
    // messageAntd.success(message, 10)
    if (stepsPf.length >= step + numberOfSteps) {
      history.push(`/register/${stepsPf[step + numberOfSteps]}`);
      setLoading(false);
    } else {
      history.push(`/register/${stepsPf[step]}`);
      setLoading(false);
    }
  };

  const handleSend = () => {
    setLoading(true);
    if (emailValido) {
      api.post('onboarding/send', {
        client_name: settings.name,
        document: checkpoint.cpfCnpj,
        contact: emailConfirm,
      })
        .then((response) => {
          setLoading(false);
          setShowConfirmationModal(false);

          setError(false);
          messageAntd.success('Um email com o codigo de verificação foi enviado', 10);
          handleAdvance(1);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          if (error.response.data.message === `The ${emailConfirm} was already registered.`) {
            setMessage('O email informado já esta em uso, gentileza utilizar outro email.');
            setTypeError('registred');
            setShowConfirmationModal(true);
            setError(true);
          } else {
            setError(true);
            setTypeError('error');
            setMessage(error.response.data.message);
            console.error('smsRequisition: ', error.response?.data?.message);
          }
        });
    }
  };

  const errorEmail = () => {
    setShowConfirmationModal(false);
    setError(false);
  };

  const componentModal = () => (
    <ModalForTransition
      visibility={showConfirmationModal && error === false}
      title={(
        <h1
          style={{
            height: '30%',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            marginBottom: '1rem',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          {' '}
          {userEmail.email}
        </h1>
   )}
      style={{ height: '100%' }}
      setVisibility={() => setShowConfirmationModal()}
      maskClosable={!loading}
    >
      <p style={{
        height: '40%', marginBottom: '1rem', width: '100%', maxWidth: '100%',
      }}
      >
        <span>Confirme seu e-mail de contato</span>
      </p>
      <DualButtonsDisposition>
        <ButtonN
          size="large"
          className="botao"
          onClick={() => {
            setShowConfirmationModal(false);
          }}
          disabled={loading}
        >
          Alterar
        </ButtonN>
        <ButtonN
          loading={loading}
          size="large"
          type="primary"
          className="botao"
          onClick={() => {
            handleSend();
          }}
          icon={loading && <Spin indicator={<LoadingOutlined />} />}
        >
          {!loading && 'Confirmar'}
        </ButtonN>
      </DualButtonsDisposition>
    </ModalForTransition>
  );

  const componentModalError = () => (
    <ModalForTransition
      visibility={showConfirmationModal && error}
      title={(
        <h1
          style={{
            height: '30%',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            marginBottom: '1rem',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          {' '}
          {userEmail.email}
        </h1>
   )}
      style={{ height: '100%' }}
    // setVisibility={() => setShowConfirmationModal()}
      maskClosable={!loading}
    >
      <p style={{
        height: '40%', marginBottom: '1rem', width: '100%', maxWidth: '100%',
      }}
      >
        <span style={{ color: 'var(--error)' }}>{message}</span>
      </p>
      <ButtonN
        type="primary"
        size="large"
        className="botao"
        onClick={() => {
          errorEmail();
        }}
        disabled={loading}
      >
        Entendido
      </ButtonN>
    </ModalForTransition>
  );

  const emailCheck = /^[a-z0-9.+_]{1,61}@[a-z0-9]{4,}.[a-z]{2,}(.[a-z]{2,})?$/gm;
  useEffect(() => {
    userEmail.email === ''
      ? setEmailValido('null')
      : emailCheck.test(userEmail.email) || userEmail.email === ''
        ? setEmailValido(false)
        : setEmailValido(true);
  }, [userEmail.email, confEmail.emailClient]);

  useEffect(() => {
    if (userEmail.email === confEmail.emailClient && confEmail.emailClient !== '') {
      setEmailConfirm(userEmail.email);
    }
  });

  const disableButton = userEmail.email !== confEmail.emailClient || emailValido === false || !userEmail.email;
  return (
    <>
      <Title>Dados de cadastro</Title>
      <InputN
        label='Email'
        id="email"
        isValid={!validEmail}
        placeholder="Insira seu e-mail pessoal"
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
        className={`ant-input ${
          userEmail === '' || userEmail.email === ''
            ? ' '
            : emailCheck.test(userEmail.email) || !userEmail.email
              ? 'is-valid'
              : 'is-invalid'
        }`}
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        value={userEmail.email}
        onChange={(e) => {
          const string = e.target.value;
          const stringFilter = string.replace(' ', '').trim();
          setUserEmail({ email: stringFilter });
        }}
        setIsValid={setEmailValido}
      />
      {!validEmail && <Alert message={messages.emailInvalid} type="error" /> }
      <InputN
        label="Confirmar E-mail"
        isValid={!validRepeatedEmail}
        id="confirmar-email"
        placeholder="Repita seu e-mail pessoal"
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        className={`${
          (userEmail.email === '' && confEmail.emailClient === '') || !confEmail.emailClient
            ? ' '
            : userEmail.email === confEmail.emailClient && !confEmail.emailClient.length < 1
              ? 'is-valid'
              : 'is-invalid'
        }`}
        value={confEmail.emailClient}
        onChange={(e) => setConfEmail({ emailClient: e.target.value.trim() })}
        // isValid={!userEmail.email === confEmail.emailClient || !confEmail.emailClient === ''}
      />
      {(confEmail.emailClient === '' && userEmail.email === '')
   || !confEmail.emailClient ? null : userEmail.email !== confEmail.emailClient ? (
     <Alert message={messages.confirmEmail} type="error" />
        ) : null}
      <ButtonN
        onClick={() => handleVerificacao()}
        disabled={disableButton || showConfirmationModal}
        type="primary"
        className="botao"
      >
        Continuar
      </ButtonN>
      <ButtonN
        onClick={async () => {
          await prevStep(2);
          history.go(-2);
        }}
        disabled={loading || showConfirmationModal}
      >
        Voltar
      </ButtonN>
      {error ? componentModalError() : componentModal()}

      <LowerLeftMessage />
    </>
  );
};

export default Email;
