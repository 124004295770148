/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Content, Input } from './styles';

function PasswordDigits({ numOfDigits, setPasswordValue, showNumbers }) {
  const [password, setPassword] = useState([]);
  const references = Array.from({ length: numOfDigits }).map(() => useRef(null));

  useEffect(() => {
    setPasswordValue(password.join(''));
  }, [password]);

  useEffect(() => {
    references[0].current.focus();
  }, []);

  const handleChange = (value, index) => {
    const newPassword = [...password];
    value === '' ? newPassword.pop() : (newPassword[index] = value);

    if (newPassword.length > 0 && newPassword.length < numOfDigits) {
      if (newPassword.length !== password.length - 1) {
        references[newPassword.length].current.focus();
      }
    }
    setPassword(newPassword);
  };

  const handleDelete = (value, index) => {
    if (value === '' && index > 0) {
      references[index - 1].current.focus();
      const newPassword = [...password];
      setPassword(newPassword);
    }
  };

  const handleClick = () => {
    if (password.length !== numOfDigits) {
      references[password.length].current.focus();
    } else {
      references[numOfDigits - 1].current.focus();
    }
  };

  const typeToInput = (index) => {
    if (showNumbers) {
      return 'text';
    } if (index < password.length - 1 || index === numOfDigits - 1) {
      return 'password';
    }
    return 'text';
  };
  return (
    <Content>
      {Array.from({ length: numOfDigits }).map((_, index) => (
        <Input
          id={index}
          key={index}
          value={password[index]?.replace(/\D/g, '')}
          type={typeToInput(index)}
          autoComplete="off"
          maxLength={1}
          onKeyDown={(e) => {
            if (['Backspace', 'Delete'].includes(e.key)) {
              handleDelete(e.target.value, index);
            }
          }}
          onClick={handleClick}
          onChange={(e) => handleChange(e.target.value, index)}
          ref={references[index]}
        />
      ))}
    </Content>
  );
}

PasswordDigits.propTypes = {
  numOfDigits: PropTypes.number,
  setPasswordValue: PropTypes.func.isRequired,
  showNumbers: PropTypes.bool,
};

PasswordDigits.defaultProps = {
  numOfDigits: 4,
  showNumbers: false,
};

export default PasswordDigits;
