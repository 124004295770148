import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'antd';
import { cnpj } from 'cpf-cnpj-validator';
import {
  Container, InputContainer, TextContainer, Title,
} from '../styles';
import InputN from '../../../components/InputN';
import ButtonN from '../../../components/ButtonN';
import { useRefreshPj } from '../../../hooks/refreshPj';
import { annualRevenue, legalNature as legalNatureList } from '../../../utilitys/annualRevenue';
import api from '../../../services/api';
import { CurrentDate } from '../../../components/CurrentDate';
import { validNumber } from '../utils/numberValidator';
import Alert from '../../../components/SpanError';
import messages from '../../../components/MessageError';

export function BasicData() {
  const { t } = useTranslation();
  const {
    basicData, setBasicData, additional, setAdditional, setStep, partner, tokenPj, accountId, userPj,
  } = useRefreshPj();
  const [isMei, setIsMei] = React.useState(false);
  const [listOfDisabled, setListOfDisabled] = useState({
    shareCapital: false,
  });
  const [displayCnae, setDisplayCnae] = useState(null);

  const handleChangeBasic = (key, value) => {
    setBasicData({ ...basicData, [key]: value });
  };

  const handleChangeAdditional = (key, value) => {
    setAdditional({ ...additional, [key]: value });
  };

  const {
    document,
    name,
  } = basicData;

  const {
    legalNature,
    numberOfAffiliates,
    numberOfEmployees,
    numberOfPartners,
    openingDate,
    revenues,
    shareCapital,
    tradingName,
  } = additional;

  useEffect(() => {
    const newList = {
      shareCapital: !!shareCapital,
    };
    setListOfDisabled(newList);
    const getCnae = async () => {
      const { data } = await api.get(`/api-id/neoway/companie/basic/${document}`, { headers: { account: accountId } });
      handleChangeAdditional('cnae', data?.cnaePrincipal?.codigo);
      setDisplayCnae(data?.cnaePrincipal?.descricao);
    };
    getCnae();
  }, []);

  const buttonAble = () => ![legalNature,
    openingDate,
    name,
    tradingName,
    isMei ? !(numberOfEmployees > 1 || !numberOfEmployees) : numberOfEmployees > 0,
    !validNumber.test(numberOfPartners),
    shareCapital,
    revenues,
    numberOfPartners,
    numberOfAffiliates].every((item) => item);

  const handleNext = async () => {
    const [openingDay, openingMonth, openingYear] = openingDate.split('/');
    const formatedOpeningDate = `${openingYear}-${openingMonth}-${openingDay}`;
    try {
      await api.put(`onboarding/companies/${userPj.id}`, {
        name,
        legal_nature: legalNature,
        revenues,
        cnae: additional.cnae,
        number_of_partners: numberOfPartners,
        number_of_affiliates: numberOfAffiliates,
        number_of_employees: numberOfEmployees,
        opening_date: formatedOpeningDate,
        trading_name: tradingName,
        share_capital: shareCapital,
      }, {
        headers: { account: accountId },
      });
      setStep('address');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (legalNature === 'MEI – Microempreendedor Individual') {
      const changeAdditional = {
        numberOfAffiliates: 0,
        numberOfEmployees: 1,
        numberOfPartners: 1,
      };
      setAdditional({ ...additional, ...changeAdditional });
      setIsMei(true);
    } else {
      setIsMei(false);
    }
  }, [legalNature]);

  const validCnpj = document?.length <= 0 || cnpj.isValid(document);
  const validNumbers = numberOfEmployees?.length <= 0 || !validNumber?.test(numberOfEmployees);
  const validPartners = numberOfPartners?.length <= 0 || !validNumber?.test(numberOfPartners);
  const validEmployesNumber = (!numberOfEmployees <= 0 && isMei && numberOfEmployees > 1) || (!numberOfEmployees <= 0 && !isMei && numberOfEmployees < 1);

  return (
    <Container>
      <InputContainer>
        <TextContainer>
          <Title>Dados de cadastro da empresa</Title>
        </TextContainer>
        <InputN.Cnpj
          placeholder="Insira o cnpj da empresa"
          label={t('CNPJ')}
          id="cnpj"
          maxLength={18}
          disabled
          value={document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1-$2.$3/$4-$5')}
          onChange={({ target: { value } }) => handleChangeBasic('document', value)}
        />
        {!validCnpj && <Alert type="error" message={messages.cnpjInvalid} />}
        <InputN.SelectandSearchModal
          id=""
          label={t('Natureza Jurídica')}
          value={legalNature}
          onChange={(value) => handleChangeAdditional('legalNature', value[0].name)}
          list={legalNatureList}
          needSearchInput
          placeholder="Selecione a natureza jurídica"
          required
          title={<Title>Escolha a natureza jurídica</Title>}
        />

        <InputN.Date
          id="abertura"
          label={t('Data de Abertura')}
          value={openingDate || ''}
          placeholder="Insira apenas números"
          style={
     !openingDate || openingDate === '0001-01-01'
       ? { color: '#D6C5BF' }
       : { color: 'black' }
    }
          onChange={(e) => {
            let { value } = e.target;
            if (value.length > 10) {
              value = value.slice(0, value.length - 1);
            }
            handleChangeAdditional('openingDate', value);
          }}
          min="1900-10-01"
          max={CurrentDate}
        />
        <InputN
          placeholder="Insira o nome completo"
          label={t('Razão social')}
          id="razaoSocial"
          value={name}
          onChange={({ target: { value } }) => handleChangeBasic('name', value)}
        />

        <InputN
          placeholder="Insira o nome completo"
          label={t('Nome fantasia')}
          id="nomeFantasia"
          value={tradingName}
          onChange={({ target: { value } }) => handleChangeAdditional('tradingName', value)}
        />

        <InputN.SelectandSearchModal
          id="capital-social"
          label="Capital Social"
          placeholder="Escolha uma opção"
          value={shareCapital ? `${shareCapital}` : ''}
          onChange={(value) => handleChangeAdditional('shareCapital', value[0].name)}
          list={annualRevenue}
          needSearchInput={false}
          title={(
            <p
              style={{
                textAlign: 'center',
                fontSize: '1.25rem',
                width: '100%',
                margin: '0',
                fontWeight: '700',
                color: 'var(--label)',
                height: '4rem',
                padding: '0 3rem',
              }}
            >
              {t('Capital Social')}
            </p>
   )}
          subTitle={
            <p style={{ fontSize: '0.875rem', padding: '0 3rem' }}>Informe o Capital Social da sua Empresa</p>
    }
        />

        <InputN
          allowZero
          onlyOneAndZero={isMei}
          isValid={validEmployesNumber}
          id="funcionarios"
          label="Número de Funcionários"
          placeholder="Insira apenas números"
          maxLength={7}
          value={numberOfEmployees}
          onChange={
            ({ target: { value } }) => (handleChangeAdditional(
              'numberOfEmployees',
              value.replace(/\D/g, ''),
            ))
          }
          numeral
          numeralDecimalMark=","
          numeralDecimalScale={0}
          delimiter="."
          message={isMei ? 'Não é possivel ter mais de 1 funcionário' : 'número não pode ser 0 ou menor que 0'}
        />
        {!numberOfEmployees?.length <= 0 && isMei && numberOfEmployees > 1 && <Alert type="error" message="Número de funcionários não pode ser maior que 1" />}
        {!numberOfEmployees?.length <= 0 && !isMei && numberOfEmployees < 1 && <Alert type="error" message="Número de funcionários não pode ser menor que 1" />}

        <InputN.SelectandSearchModal
          id="faturamentoAnual"
          label={t('Faturamento Anual')}
          value={revenues}
          onChange={(value) => handleChangeAdditional('revenues', value[0].name)}
          list={annualRevenue}
          required
          title={<Title>Escolha seu faturamento anual</Title>}
        />

        <InputN
          placeholder="Insira apenas numeros"
          label={t('Número de Sócios')}
          maxLength={5}
          id="numeroSocio"
          isValid={validNumber.test(numberOfPartners)}
          disabled={isMei}
          value={numberOfPartners}
          onChange={
            ({ target: { value } }) => handleChangeAdditional(
              'numberOfPartners',
              value,
            )
            }
        />
        {!validPartners && <Alert type="error" message="Número de sócios não poder ser igual á 0" />}
        <Dropdown
          overlay={(
            <div
              style={{
                width: '20rem',
                border: 'solid var(--primary) 1.5px',
                backgroundColor: '#ffffff',
                padding: '5px',
                borderRadius: '8px',
                margin: 'auto',
              }}
            >
              {displayCnae || 'Carregando...'}
            </div>
   )}
          placement="topCenter"
        >
          <button
            type="button"
            style={{
              width: '100%', backgroundColor: 'transparent', border: 'none', textAlign: 'left',
            }}
          >
            <InputN
              id="categoria"
              label="Categoria de Négocio"
              placeholder="Escolha uma opção"
              value={displayCnae || 'Carregando...'}
      // onChange={() =>
      //  setCnpjData({ ...cnpjData, categoriaDeNegocios: checkpoint.cnpjInfo.cnaePrincipal.descricao })
      // }
              readOnly
              disabled
              style={{ width: '100%' }}
            />
          </button>
        </Dropdown>

        <InputN
          placeholder={isMei ? '0' : 'Insira apenas números'}
          label={t('Quantidade de Filiais')}
          maxLength={5}
          id="quantidadeFiliais"
          disabled={isMei}
          value={numberOfAffiliates}
          onChange={({ target: { value } }) => (isMei ? handleChangeAdditional('numberOfAffiliates', '0') : handleChangeAdditional('numberOfAffiliates', value))}
        />
        {isMei && numberOfAffiliates > 0 && <Alert type="error" message="Número de filiais não pode ser maior que 0" />}
      </InputContainer>
      <ButtonN
        style={{
          marginBottom: '10px',
        }}
        disabled={buttonAble()}
        type="primary"
        onClick={handleNext}
      >
        Continuar
      </ButtonN>
    </Container>
  );
}
