/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faHiking,
  faGlasses,
  faCamera,
  faFaceLaugh,
  faPerson,
  faCircleExclamation,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import Webcam from "react-webcam";
import { useHistory } from "react-router-dom";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSettings } from "../../../../../../hooks/settings";
import { useCheckpoint } from "../../../../../../hooks/checkpoint";
import { useSteps } from "../../../../../../hooks/steps";
import ButtonN from "../../../../../../components/ButtonN";
import {
  Section,
  TextStyled,
  InfoStyled,
  CamStyled,
  Preview,
  ButtonPicture,
  CoverButtonPicture,
  ButtonPhoto,
  FalseModel,
  OverlayContainer,
  Container,
  Box,
  Corner1,
  Corner2,
  Corner3,
  Corner4,
  ArrowContainer,
  ArrowDown,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
  FaceContainer,
} from "./styles";
import { Title } from "../../../../styles";
import api from "../../../../../../services/api";
import eyesMask from "../../../../../../assets/eyes-mask.png";
import {
  HeaderArea,
  WhiteText,
  WhiteTitle,
} from "../../../../../RefreshData/Components/Cam/styles";
import {
  RedText,
  TipsArea,
  TitleArea,
} from "../../../../../RefreshData/Validation/Selfie/styles";
import { CheckpointService } from "../../../../../../services/endPointRegister/CheckpointService";

const Cam = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const {
    nextStep,
    step,
    stepsPf,
    exactStep,
    resetStep,
    setPhotoAttempts,
    photoAttempts,
  } = useSteps();
  const history = useHistory();
  const { getEmailSupportMessage, settings } = useSettings();
  const [image, setImage] = useState("");
  const webcamRef = React.useRef(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [photoData, setPhotoData] = useState({ photo: "" });
  const [loading, setLoading] = useState(false);
  const [randomNumber, setRandomNumber] = useState(5);
  const [, setError] = useState("");
  const [frontalSelfie, setFrontalSelfie] = useState(false);
  const [downSelfie, setDownSelfie] = useState(false);
  const [upSelfie, setUpSelfie] = useState(false);
  const [leftSelfie, setLeftSelfie] = useState(false);
  const [rightSelfie, setRightSelfie] = useState(false);
  const [frontalSelfieDone, setFrontalSelfieDone] = useState(false);
  const [downSelfieDone, setDownSelfieDone] = useState(false);
  const [upSelfieDone, setUpSelfieDone] = useState(false);
  const [leftSelfieDone, setLeftSelfieDone] = useState(false);
  const [rightSelfieDone, setRightSelfieDone] = useState(false);
  const token = localStorage.getItem("@qesh:token");
  const lastAttempt = photoAttempts === 1;
  const [selfieUnique, setSelfieUnique] = React.useState(false);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setPhotoData({ ...photoData, photo: imageSrc });
  }, [webcamRef, photoData]);

  React.useEffect(() => {
    if (settings.name === "nmpay" || settings.name === 'qesh') {
      getCheckpoints();
    }
  }, [settings.name]);

  useEffect(() => {
    const random = frontalSelfieDone ? Math.floor(Math.random() * 4) : 5;
    setRandomNumber(random);
    if (!cameraActive) {
      message.success(`Hora da selfie! Por favor, ative sua câmera!`, 5);
    }
    if (
      localStorage.getItem("@qesh:token") &&
      sessionStorage.getItem("@qesh:step") === "10"
    ) {
      exactStep(10);
    }
  }, []);

  React.useEffect(() => {
    if (
      !frontalSelfieDone ||
      !downSelfieDone ||
      !leftSelfieDone ||
      !rightSelfieDone ||
      !upSelfieDone
    ) {
      setImage("");
    }
  }, [
    frontalSelfieDone,
    downSelfieDone,
    leftSelfieDone,
    rightSelfieDone,
    upSelfieDone,
  ]);

  useEffect(() => {
    randomStep();
  }, [randomNumber]);

  const getCheckpoints = async () => {
    try {
      const { res } = await CheckpointService(settings.name);

      const hasSelfieUnique = res.response.find(
        (checkpoint) => checkpoint === "SelfieUnique"
      );

      setSelfieUnique(hasSelfieUnique ? true : false);
    } catch (error) {
      console.log("Error getCheckpoints", error);
    }
  };

  const randomStep = () => {
    if (selfieUnique && !frontalSelfieDone) {
      setUpSelfie(false);
      setLeftSelfie(false);
      setRightSelfie(false);
      setDownSelfie(false);
      setFrontalSelfie(true);
    }

    if (randomNumber === 1 && !downSelfieDone) {
      setUpSelfie(false);
      setLeftSelfie(false);
      setRightSelfie(false);
      setFrontalSelfie(false);
      setDownSelfie(true);
    }
    if (randomNumber === 2 && !upSelfieDone) {
      setDownSelfie(false);
      setLeftSelfie(false);
      setRightSelfie(false);
      setFrontalSelfie(false);
      setUpSelfie(true);
    }
    if (randomNumber === 3 && !leftSelfieDone) {
      setUpSelfie(false);
      setRightSelfie(false);
      setFrontalSelfie(false);
      setDownSelfie(false);
      setLeftSelfie(true);
    }
    if (randomNumber === 4 && !rightSelfieDone) {
      setUpSelfie(false);
      setLeftSelfie(false);
      setFrontalSelfie(false);
      setDownSelfie(false);
      setRightSelfie(true);
    }
    if (randomNumber === 5 && !frontalSelfieDone) {
      setUpSelfie(false);
      setLeftSelfie(false);
      setRightSelfie(false);
      setDownSelfie(false);
      setFrontalSelfie(true);
    }
  };

  const videoConstraints = {
    width: 300,
    height: 400,
    facingMode: "user",
  };

  const handleAdvance = async () => {
    setCheckpoint({ ...checkpoint, photoData });
    nextStep();
    if (stepsPf.length >= step + 1) {
      history.replace(`/register/${stepsPf[step + 1]}`);
    } else {
      history.replace(`/register/${stepsPf[step]}`);
    }
  };

  const handleFrontalSelfie = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", photoData.photo);
    fetch(photoData.photo)
      .then((res) => res.blob())
      .then((blob) => {
        const formData = new FormData();
        const file = new File([blob], "selfie.png");
        formData.append("file", file);
        api
          .post("onboarding/documents/SELFIE", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `bearer ${token}`,
            },
          })
          .then(() => {
            setLoading(false);
            setError(false);
            setImage("");
            if (selfieUnique && frontalSelfie) {
              handleAdvance();
            } else if (!downSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(true);
            } else if (!upSelfieDone) {
              setDownSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setUpSelfie(true);
            } else if (!leftSelfieDone) {
              setUpSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(false);
              setLeftSelfie(true);
            } else if (!rightSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(false);
              setRightSelfie(true);
            } else if (
              downSelfieDone &&
              upSelfieDone &&
              leftSelfieDone &&
              rightSelfieDone
            ) {
              handleAdvance();
            }
            setFrontalSelfieDone(true);
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
            setImage("");
            setPhotoAttempts(error?.response?.data?.message?.attempts);
            if (
              error?.response?.data?.message?.message ===
              "Could not detect a face. Try another image. "
            ) {
              message?.error(
                "Não foi possível detectar sua face. Tente novamente.",
                6
              );
            } else if (
              error.response.data.message.message ===
              "Please try to avoid excessive light in the background. "
            ) {
              return message?.error(
                "Evite excesso de luz no ambiente. Tente novamente. ",
                6
              );
            } else if (
              error?.response?.data?.message?.message ===
              "The attempt limit for the day has been exceeded"
            ) {
              return message?.error(
                `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`,
                7
              );
            } else {
              return message?.error(
                `Erro ao validar a imagem, gentileza envie outra foto.`,
                7
              );
            }
          });
      });
  };

  const handleDownSelfie = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", photoData.photo);

    fetch(photoData.photo)
      .then((res) => res.blob())
      .then((blob) => {
        const formData = new FormData();
        const file = new File([blob], "selfie.png");
        formData.append("file", file);
        api
          .post("onboarding/documents/SELFIED", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `bearer ${token}`,
            },
          })
          .then(() => {
            setLoading(false);
            setError(false);
            setImage("");
            if (!frontalSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setDownSelfie(false);
              setFrontalSelfie(true);
            } else if (!upSelfieDone) {
              setDownSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setUpSelfie(true);
            } else if (!leftSelfieDone) {
              setUpSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(false);
              setLeftSelfie(true);
            } else if (!rightSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(false);
              setRightSelfie(true);
            } else if (
              frontalSelfieDone &&
              upSelfieDone &&
              leftSelfieDone &&
              rightSelfieDone
            ) {
              handleAdvance();
            }
            setDownSelfieDone(true);
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
            setImage("");
            setPhotoAttempts(error?.response?.data?.message?.attempts);
            if (
              error.response.data.message.message ===
              "Could not detect a face. Try another image. "
            ) {
              message.error(
                "Não foi possível detectar sua face. Tente novamente.",
                6
              );
            } else if (
              error.response.data.message.message ===
              "Please try to avoid excessive light in the background. "
            ) {
              return message.error(
                "Evite excesso de luz no ambiente. Tente novamente. ",
                6
              );
            } else if (error.response.data.message.attempts < 1) {
              return message.error(
                `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`,
                7
              );
            } else {
              return message.error(
                `Erro ao validar a imagem, gentileza envie outra foto.`,
                7
              );
            }
          });
      });
  };
  const handleUpSelfie = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", photoData.photo);

    fetch(photoData.photo)
      .then((res) => res.blob())
      .then((blob) => {
        const formData = new FormData();
        const file = new File([blob], "selfie.png");
        formData.append("file", file);
        api
          .post("onboarding/documents/SELFIEU", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `bearer ${token}`,
            },
          })
          .then(() => {
            setLoading(false);
            setError(false);
            setImage("");
            if (!frontalSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setDownSelfie(false);
              setFrontalSelfie(true);
            } else if (!downSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(true);
            } else if (!leftSelfieDone) {
              setUpSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(false);
              setLeftSelfie(true);
            } else if (!rightSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(false);
              setRightSelfie(true);
            } else if (
              frontalSelfieDone &&
              downSelfieDone &&
              leftSelfieDone &&
              rightSelfieDone
            ) {
              handleAdvance();
            }
            setUpSelfieDone(true);
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
            setImage("");
            setPhotoAttempts(error?.response?.data?.message?.attempts);
            if (
              error.response.data.message.message ===
              "Could not detect a face. Try another image. "
            ) {
              message.error(
                "Não foi possível detectar sua face. Tente novamente.",
                6
              );
            } else if (
              error.response.data.message.message ===
              "Please try to avoid excessive light in the background. "
            ) {
              return message.error(
                "Evite excesso de luz no ambiente. Tente novamente. ",
                6
              );
            } else if (error.response.data.message.attempts < 1) {
              return message.error(
                `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`,
                7
              );
            } else {
              return message.error(
                `Erro ao validar a imagem, gentileza envie outra foto.`,
                7
              );
            }
          });
      });
  };
  const handleLeftSelfie = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", photoData.photo);

    fetch(photoData.photo)
      .then((res) => res.blob())
      .then((blob) => {
        const formData = new FormData();
        const file = new File([blob], "selfie.png");
        formData.append("file", file);
        api
          .post("onboarding/documents/SELFIEL", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `bearer ${token}`,
            },
          })
          .then(() => {
            setLoading(false);
            setError(false);
            setImage("");
            if (!frontalSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setDownSelfie(false);
              setFrontalSelfie(true);
            } else if (!downSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(true);
            } else if (!upSelfieDone) {
              setDownSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setUpSelfie(true);
            } else if (!rightSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(false);
              setRightSelfie(true);
            } else if (
              frontalSelfieDone &&
              downSelfieDone &&
              upSelfieDone &&
              rightSelfieDone
            ) {
              handleAdvance();
            }
            setLeftSelfieDone(true);
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
            setImage("");
            setPhotoAttempts(error?.response?.data?.message?.attempts);
            if (
              error.response.data.message.message ===
              "Could not detect a face. Try another image. "
            ) {
              message.error(
                "Não foi possível detectar sua face. Tente novamente.",
                6
              );
            } else if (
              error.response.data.message.message ===
              "Please try to avoid excessive light in the background. "
            ) {
              return message.error(
                "Evite excesso de luz no ambiente. Tente novamente. ",
                6
              );
            } else if (error.response.data.message.attempts < 1) {
              return message.error(
                `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`,
                7
              );
            } else {
              return message.error(
                `Erro ao validar a imagem, gentileza envie outra foto.`,
                7
              );
            }
          });
      });
  };
  const handleRightSelfie = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", photoData.photo);

    fetch(photoData.photo)
      .then((res) => res.blob())
      .then((blob) => {
        const formData = new FormData();
        const file = new File([blob], "selfie.png");
        formData.append("file", file);
        api
          .post("onboarding/documents/SELFIER", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `bearer ${token}`,
            },
          })
          .then(() => {
            setLoading(false);
            setError(false);
            setImage("");
            if (!frontalSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setDownSelfie(false);
              setFrontalSelfie(true);
            } else if (!downSelfieDone) {
              setUpSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(true);
            } else if (!upSelfieDone) {
              setDownSelfie(false);
              setLeftSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setUpSelfie(true);
            } else if (!leftSelfieDone) {
              setUpSelfie(false);
              setRightSelfie(false);
              setFrontalSelfie(false);
              setDownSelfie(false);
              setLeftSelfie(true);
            } else if (
              frontalSelfieDone &&
              downSelfieDone &&
              upSelfieDone &&
              leftSelfieDone
            ) {
              handleAdvance();
            }
            setRightSelfieDone(true);
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
            setImage("");
            setPhotoAttempts(error?.response?.data?.message?.attempts);
            if (
              error.response.data.message.message ===
              "Could not detect a face. Try another image. "
            ) {
              message.error(
                "Não foi possível detectar sua face. Tente novamente.",
                6
              );
            } else if (
              error.response.data.message.message ===
              "Please try to avoid excessive light in the background. "
            ) {
              return message.error(
                "Evite excesso de luz no ambiente. Tente novamente. ",
                6
              );
            } else if (error.response.data.message.attempts < 1) {
              return message.error(
                `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`,
                7
              );
            } else {
              return message.error(
                `Erro ao validar a imagem, gentileza envie outra foto.`,
                7
              );
            }
          });
      });
  };

  return (
    <>
      <Section>
        {frontalSelfie && !frontalSelfieDone && (
          <CamStyled>
            <HeaderArea>
              {lastAttempt ? (
                <>
                  <WhiteTitle>
                    Parece que tivemos algum problema com a sua foto
                  </WhiteTitle>
                  <WhiteTitle>
                    Você só tem mais{" "}
                    <span style={{ color: "#e22012" }}>1 tentativa</span>!
                  </WhiteTitle>
                </>
              ) : (
                <>
                  <WhiteTitle>Hora da foto!</WhiteTitle>
                  <WhiteText>
                    Para garantir que ninguém esteja se passando por você,
                    precisamos que nos envie uma foto do seu rosto. Você terá
                    apenas{" "}
                    <span style={{ color: "#e22012" }}>5 tentativas </span>
                    para tirar a foto, então leia bem e siga todas as
                    instruções. Vamos lá?
                  </WhiteText>
                </>
              )}
            </HeaderArea>
            <>
              {image === "" ? (
                <Box>
                  <Container>
                    <Webcam
                      audio={false}
                      videoConstraints={videoConstraints}
                      screenshotFormat="image/png"
                      ref={webcamRef}
                      screenshotQuality={1}
                      imageSmoothing
                      mirrored
                      style={{ objectFit: "cover" }}
                      width={videoConstraints.width}
                      height={videoConstraints.height}
                    />

                    <OverlayContainer>
                      <Corner1 borderColor={lastAttempt && "#e22012"} />
                      <Corner2 borderColor={lastAttempt && "#e22012"} />
                      <Corner3 borderColor={lastAttempt && "#e22012"} />
                      <Corner4 borderColor={lastAttempt && "#e22012"} />
                      <img
                        style={{ width: "300px", height: "400px" }}
                        src={eyesMask}
                        alt="eyes-mask"
                      />
                    </OverlayContainer>
                  </Container>
                </Box>
              ) : (
                <Preview
                  src={image}
                  style={{ objectFit: "auto" }}
                  width={videoConstraints.width}
                  height={videoConstraints.height}
                />
              )}
            </>

            {image !== "" ? (
              <ButtonPhoto>
                <ButtonN
                  style={{ background: "none" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setImage("");
                  }}
                  disabled={loading}
                >
                  Tirar outra
                </ButtonN>
                <ButtonN
                  onClick={() => {
                    handleFrontalSelfie();
                  }}
                  type="primary"
                  loading={loading}
                  icon={loading && <Spin indicator={<LoadingOutlined />} />}
                >
                  {loading ? "Enviando" : "Ficou boa"}
                </ButtonN>
              </ButtonPhoto>
            ) : (
              <ButtonPicture
                onClick={(e) => {
                  setCameraActive(true);
                  e.preventDefault();
                  capture(() => {});
                }}
              >
                <FontAwesomeIcon
                  icon={faCamera}
                  size="2x"
                  color="#ffff"
                  onClick={(e) => {
                    setCameraActive(true);
                    e.preventDefault();
                    capture(() => {});
                  }}
                />
              </ButtonPicture>
            )}
          </CamStyled>
        )}
        {downSelfie && !downSelfieDone && (
          <CamStyled>
            <HeaderArea>
              {lastAttempt ? (
                <>
                  <WhiteTitle>
                    Parece que tivemos algum problema com a sua foto
                  </WhiteTitle>
                  <WhiteTitle>
                    Você só tem mais{" "}
                    <span style={{ color: "#e22012" }}>1 tentativa</span>!
                  </WhiteTitle>
                </>
              ) : (
                <>
                  <WhiteTitle>Hora da foto!</WhiteTitle>
                  <WhiteText>
                    Para garantir que ninguém esteja se passando por você,
                    precisamos que nos envie uma foto do seu rosto. Você terá
                    apenas{" "}
                    <span style={{ color: "#e22012" }}>5 tentativas </span>
                    para tirar a foto, então leia bem e siga todas as
                    instruções. Vamos lá?
                  </WhiteText>
                </>
              )}
            </HeaderArea>
            <>
              {image === "" ? (
                <Box>
                  <Container>
                    <Webcam
                      audio={false}
                      videoConstraints={videoConstraints}
                      screenshotFormat="image/png"
                      ref={webcamRef}
                      screenshotQuality={1}
                      imageSmoothing
                      mirrored
                      style={{ objectFit: "cover" }}
                      width={videoConstraints.width}
                      height={videoConstraints.height}
                    />

                    <OverlayContainer>
                      <Corner1 borderColor={lastAttempt && "#e22012"} />
                      <Corner2 borderColor={lastAttempt && "#e22012"} />
                      <Corner3 borderColor={lastAttempt && "#e22012"} />
                      <Corner4 borderColor={lastAttempt && "#e22012"} />
                      <ArrowContainer>
                        <FaceContainer>
                          <ArrowDown>
                            <FontAwesomeIcon
                              icon={faArrowDown}
                              style={{ color: "#42C1C7", fontSize: "60" }}
                            />
                          </ArrowDown>
                        </FaceContainer>
                      </ArrowContainer>
                    </OverlayContainer>
                  </Container>
                </Box>
              ) : (
                <Preview
                  src={image}
                  style={{ objectFit: "auto" }}
                  width={videoConstraints.width}
                  height={videoConstraints.height}
                />
              )}
            </>

            {image !== "" ? (
              <ButtonPhoto>
                <ButtonN
                  style={{ background: "none" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setImage("");
                  }}
                  disabled={loading}
                >
                  Tirar outra
                </ButtonN>
                <ButtonN
                  onClick={() => {
                    handleDownSelfie();
                  }}
                  type="primary"
                  loading={loading}
                  icon={loading && <Spin indicator={<LoadingOutlined />} />}
                >
                  {loading ? "Enviando" : "Ficou boa"}
                </ButtonN>
              </ButtonPhoto>
            ) : (
              <CoverButtonPicture
                onClick={(e) => {
                  setCameraActive(true);
                  e.preventDefault();
                  capture(() => {});
                }}
              >
                <ButtonPicture
                  onClick={(e) => {
                    setCameraActive(true);
                    e.preventDefault();
                    capture(() => {});
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCamera}
                    size="2x"
                    color="#ffff"
                    onClick={(e) => {
                      setCameraActive(true);
                      e.preventDefault();
                      capture(() => {});
                    }}
                  />
                </ButtonPicture>
              </CoverButtonPicture>
            )}
          </CamStyled>
        )}
        {upSelfie && !upSelfieDone && (
          <CamStyled>
            <HeaderArea>
              {lastAttempt ? (
                <>
                  <WhiteTitle>
                    Parece que tivemos algum problema com a sua foto
                  </WhiteTitle>
                  <WhiteTitle>
                    Você só tem mais{" "}
                    <span style={{ color: "#e22012" }}>1 tentativa</span>!
                  </WhiteTitle>
                </>
              ) : (
                <>
                  <WhiteTitle>Hora da foto!</WhiteTitle>
                  <WhiteText>
                    Para garantir que ninguém esteja se passando por você,
                    precisamos que nos envie uma foto do seu rosto. Você terá
                    apenas{" "}
                    <span style={{ color: "#e22012" }}>5 tentativas </span>
                    para tirar a foto, então leia bem e siga todas as
                    instruções. Vamos lá?
                  </WhiteText>
                </>
              )}
            </HeaderArea>
            <>
              {image === "" ? (
                <Box>
                  <Container>
                    <Webcam
                      audio={false}
                      videoConstraints={videoConstraints}
                      screenshotFormat="image/png"
                      ref={webcamRef}
                      screenshotQuality={1}
                      imageSmoothing
                      mirrored
                      style={{ objectFit: "cover" }}
                      width={videoConstraints.width}
                      height={videoConstraints.height}
                    />

                    <OverlayContainer>
                      <Corner1 borderColor={lastAttempt && "#e22012"} />
                      <Corner2 borderColor={lastAttempt && "#e22012"} />
                      <Corner3 borderColor={lastAttempt && "#e22012"} />
                      <Corner4 borderColor={lastAttempt && "#e22012"} />
                      <ArrowContainer>
                        <FaceContainer>
                          <ArrowUp>
                            <FontAwesomeIcon
                              icon={faArrowUp}
                              style={{ color: "#42C1C7", fontSize: "60" }}
                            />
                          </ArrowUp>
                        </FaceContainer>
                      </ArrowContainer>
                    </OverlayContainer>
                  </Container>
                </Box>
              ) : (
                <Preview
                  src={image}
                  style={{ objectFit: "auto" }}
                  width={videoConstraints.width}
                  height={videoConstraints.height}
                />
              )}
            </>

            {image !== "" ? (
              <ButtonPhoto>
                <ButtonN
                  style={{ background: "none" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setImage("");
                  }}
                  disabled={loading}
                >
                  Tirar outra
                </ButtonN>
                <ButtonN
                  onClick={() => {
                    handleUpSelfie();
                  }}
                  type="primary"
                  loading={loading}
                  icon={loading && <Spin indicator={<LoadingOutlined />} />}
                >
                  {loading ? "Enviando" : "Ficou boa"}
                </ButtonN>
              </ButtonPhoto>
            ) : (
              <ButtonPicture
                onClick={(e) => {
                  setCameraActive(true);
                  e.preventDefault();
                  capture(() => {});
                }}
              >
                <FontAwesomeIcon
                  icon={faCamera}
                  size="2x"
                  color="#ffff"
                  onClick={(e) => {
                    setCameraActive(true);
                    e.preventDefault();
                    capture(() => {});
                  }}
                />
              </ButtonPicture>
            )}
          </CamStyled>
        )}
        {leftSelfie && !leftSelfieDone && (
          <CamStyled>
            <HeaderArea>
              {lastAttempt ? (
                <>
                  <WhiteTitle>
                    Parece que tivemos algum problema com a sua foto
                  </WhiteTitle>
                  <WhiteTitle>
                    Você só tem mais{" "}
                    <span style={{ color: "#e22012" }}>1 tentativa</span>!
                  </WhiteTitle>
                </>
              ) : (
                <>
                  <WhiteTitle>Hora da foto!</WhiteTitle>
                  <WhiteText>
                    Para garantir que ninguém esteja se passando por você,
                    precisamos que nos envie uma foto do seu rosto. Você terá
                    apenas{" "}
                    <span style={{ color: "#e22012" }}>5 tentativas </span>
                    para tirar a foto, então leia bem e siga todas as
                    instruções. Vamos lá?
                  </WhiteText>
                </>
              )}
            </HeaderArea>
            <>
              {image === "" ? (
                <Box>
                  <Container>
                    <Webcam
                      audio={false}
                      videoConstraints={videoConstraints}
                      screenshotFormat="image/png"
                      ref={webcamRef}
                      screenshotQuality={1}
                      imageSmoothing
                      mirrored
                      style={{ objectFit: "cover" }}
                      width={videoConstraints.width}
                      height={videoConstraints.height}
                    />

                    <OverlayContainer>
                      <Corner1 borderColor={lastAttempt && "#e22012"} />
                      <Corner2 borderColor={lastAttempt && "#e22012"} />
                      <Corner3 borderColor={lastAttempt && "#e22012"} />
                      <Corner4 borderColor={lastAttempt && "#e22012"} />
                      <ArrowContainer>
                        <FaceContainer>
                          <ArrowLeft>
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              style={{ color: "#42C1C7", fontSize: "60" }}
                            />
                          </ArrowLeft>
                        </FaceContainer>
                      </ArrowContainer>
                    </OverlayContainer>
                  </Container>
                </Box>
              ) : (
                <Preview
                  src={image}
                  style={{ objectFit: "auto" }}
                  width={videoConstraints.width}
                  height={videoConstraints.height}
                />
              )}
            </>

            {image !== "" ? (
              <ButtonPhoto>
                <ButtonN
                  style={{ background: "none" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setImage("");
                  }}
                  disabled={loading}
                >
                  Tirar outra
                </ButtonN>
                <ButtonN
                  onClick={() => {
                    handleLeftSelfie();
                  }}
                  type="primary"
                  loading={loading}
                  icon={loading && <Spin indicator={<LoadingOutlined />} />}
                >
                  {loading ? "Enviando" : "Ficou boa"}
                </ButtonN>
              </ButtonPhoto>
            ) : (
              <ButtonPicture
                onClick={(e) => {
                  setCameraActive(true);
                  e.preventDefault();
                  capture(() => {});
                }}
              >
                <FontAwesomeIcon
                  icon={faCamera}
                  size="2x"
                  color="#ffff"
                  onClick={(e) => {
                    setCameraActive(true);
                    e.preventDefault();
                    capture(() => {});
                  }}
                />
              </ButtonPicture>
            )}
          </CamStyled>
        )}
        {rightSelfie && !rightSelfieDone && (
          <CamStyled>
            <HeaderArea>
              {lastAttempt ? (
                <>
                  <WhiteTitle>
                    Parece que tivemos algum problema com a sua foto
                  </WhiteTitle>
                  <WhiteTitle>
                    Você só tem mais{" "}
                    <span style={{ color: "#e22012" }}>1 tentativa</span>!
                  </WhiteTitle>
                </>
              ) : (
                <>
                  <WhiteTitle>Hora da foto!</WhiteTitle>
                  <WhiteText>
                    Para garantir que ninguém esteja se passando por você,
                    precisamos que nos envie uma foto do seu rosto. Você terá
                    apenas{" "}
                    <span style={{ color: "#e22012" }}>5 tentativas </span>
                    para tirar a foto, então leia bem e siga todas as
                    instruções. Vamos lá?
                  </WhiteText>
                </>
              )}
            </HeaderArea>
            <>
              {image === "" ? (
                <Box>
                  <Container>
                    <Webcam
                      audio={false}
                      videoConstraints={videoConstraints}
                      screenshotFormat="image/png"
                      ref={webcamRef}
                      screenshotQuality={1}
                      imageSmoothing
                      mirrored
                      style={{ objectFit: "cover" }}
                      width={videoConstraints.width}
                      height={videoConstraints.height}
                    />

                    <OverlayContainer>
                      <Corner1 borderColor={lastAttempt && "#e22012"} />
                      <Corner2 borderColor={lastAttempt && "#e22012"} />
                      <Corner3 borderColor={lastAttempt && "#e22012"} />
                      <Corner4 borderColor={lastAttempt && "#e22012"} />
                      <ArrowContainer>
                        <FaceContainer>
                          <ArrowRight>
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              style={{ color: "#42C1C7", fontSize: "60" }}
                            />
                          </ArrowRight>
                        </FaceContainer>
                      </ArrowContainer>
                    </OverlayContainer>
                  </Container>
                </Box>
              ) : (
                <Preview
                  src={image}
                  style={{ objectFit: "auto" }}
                  width={videoConstraints.width}
                  height={videoConstraints.height}
                />
              )}
            </>

            {image !== "" ? (
              <ButtonPhoto>
                <ButtonN
                  style={{ background: "none" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setImage("");
                  }}
                  disabled={loading}
                >
                  Tirar outra
                </ButtonN>
                <ButtonN
                  onClick={() => {
                    handleRightSelfie();
                  }}
                  type="primary"
                  loading={loading}
                  icon={loading && <Spin indicator={<LoadingOutlined />} />}
                >
                  {loading ? "Enviando" : "Ficou boa"}
                </ButtonN>
              </ButtonPhoto>
            ) : (
              <ButtonPicture
                onClick={(e) => {
                  setCameraActive(true);
                  e.preventDefault();
                  capture(() => {});
                }}
              >
                <FontAwesomeIcon
                  icon={faCamera}
                  size="2x"
                  color="#ffff"
                  onClick={(e) => {
                    setCameraActive(true);
                    e.preventDefault();
                    capture(() => {});
                  }}
                />
              </ButtonPicture>
            )}
          </CamStyled>
        )}
      </Section>
      <TitleArea style={{ width: "100%" }}>
        {lastAttempt ? (
          <>
            <Title>
              Você só tem mais{" "}
              <span style={{ color: "#e22012" }}>1 tentativa</span>!
            </Title>
            <TextStyled>
              Você só tem mais uma chance de tirar uma foto dentro dos padrões
              adequados
            </TextStyled>
          </>
        ) : (
          <>
            <Title>Confira algumas dicas para você</Title>
            <Title> se sair bem na foto</Title>
          </>
        )}
      </TitleArea>
      {lastAttempt && (
        <>
          <TipsArea style={{ width: "100%" }}>
            <RedText>
              <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
              Verifique a distância entre seu rosto e o câmera
            </RedText>
            <RedText>
              <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
              Confira a orientação da foto (paisagem/horizontal)
            </RedText>
            <RedText>
              <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
              Confira se sua foto possui nitidez suficiente
            </RedText>
            <RedText>
              <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
              Certifique de que seu rosto está centralizado no local indicado
            </RedText>
          </TipsArea>
          <Title style={{ marginTop: "5rem" }}>Além disso</Title>
        </>
      )}
      <TextStyled>
        <FontAwesomeIcon icon={faFaceLaugh} />
        Lembre-se de encaixar o seu rosto no círculo para melhores resultados.
      </TextStyled>
      <TextStyled>
        <FontAwesomeIcon icon={faSun} />
        Encontre um lugar com boa iluminação.
      </TextStyled>
      <TextStyled>
        <FontAwesomeIcon icon={faPerson} />
        Essa é uma foto formal, então não faça poses ou caretas.
      </TextStyled>
      <TextStyled>
        <FontAwesomeIcon icon={faGlasses} />
        Retire os acessórios que cobrem seu rosto, como óculos e bonés.
      </TextStyled>
      <TextStyled>
        <FontAwesomeIcon icon={faHiking} />
        Siga as instruções apresentadas na tela.
      </TextStyled>
      <InfoStyled>
        Quando essa mensagem aparecer, clique em permitir para que possamos
        acessar a sua câmera.
      </InfoStyled>
      <FalseModel src="https://res.cloudinary.com/djbzvkmqm/image/upload/v1638384172/Platter_3_gqtbtn.png" />
    </>
  );
};

export default Cam;
