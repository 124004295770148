import {
  createContext, useContext, useState, useEffect, React,
} from 'react';
import { listOfNationality } from '../utilitys/listOfNationality';
import { HandleRefreshData } from '../services/refreshData';
import api from '../services/api';

const RefreshContext = createContext({});

// eslint-disable-next-line react/prop-types
export function RefreshProvider({ children }) {
  const [user, setUser] = useState({});
  const [step, setStep] = useState('basicData');
  const [token, setToken] = useState(null);
  const [photoAttempts, setPhotoAttempts] = useState(5);
  const [basicData, setBasicData] = useState({
    name: '',
    motherName: '',
    phone: '',
    email: '',
    nationality: '',
  });
  const [address, setAddress] = useState({
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  });
  const [additional, setAdditional] = useState({
    monthlyIncome: '',
    patrimony: '',
    relationship: '',
    occupation: '',
    education: '',
    isPEP: false,
    nationality: '',
  });
  const [refreshApi, setRefreshApi] = useState(null);

  const formatFullName = (name) => {
    const nameArray = name.split(' ');
    const newNameArray = nameArray.map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
    return newNameArray.join(' ');
  };

  const resetRefresh = () => {
    setBasicData({
      name: '',
      motherName: '',
      phone: '',
      email: '',
      nationality: '',
    });
    setAddress({
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    });
    setAdditional({
      monthlyIncome: '',
      patrimony: '',
      relationship: '',
      occupation: '',
      education: '',
      isPEP: false,
      nationality: '',
    });
    setStep('basicData');
    setToken(null);
    setUser({});
  };

  const getAddress = () => {
    const {
      baas: {
        address: {
          street, number, neighborhood, city, state, zip_code: zipCode, country, complement,
        },
      },
    } = user;

    return {
      street,
      number,
      neighborhood,
      city,
      state,
      zipCode,
      country,
      complement,
    };
  };

  const getBasicData = () => {
    const {
      baas: {
        user: { email, phone, name },
        additional: { mother_name: motherName, nationality },
      },
    } = user;
    return {
      email,
      phone,
      motherName: formatFullName(motherName),
      document,
      name: formatFullName(name),
      nationality: listOfNationality.find((item) => item.id === nationality)?.name || nationality,
    };
  };

  const getAdditional = () => {
    const {
      baas: {
        additional: {
          monthly_income: monthlyIncome, patrimony, relationship, occupation, education, isPEP, nationality,
        },
      },
    } = user;
    return {
      monthlyIncome,
      patrimony,
      relationship,
      occupation,
      education,
      isPEP: isPEP || false,
      nationality,
    };
  };

  useEffect(() => {
    if (user.baas) {
      setBasicData(getBasicData());
      setAddress(getAddress());
      setAdditional(getAdditional());
      const {
        baas: {
          additional: { id: additionalId },
          address: { id: addressId },
          accounts,
        },
        id: userId,
      } = user;
      const accountId = accounts[0].id;
      setRefreshApi(new HandleRefreshData(token, userId, additionalId, addressId, accountId));
    }
    if (token) {
      api.defaults.headers.authorization = `bearer ${token}`;
    }
  }, [user, token]);

  return (
    <RefreshContext.Provider
      value={{
        setUser,
        user,
        token,
        setToken,
        refreshApi,
        basicData,
        additional,
        address,
        setBasicData,
        setAddress,
        setAdditional,
        step,
        setStep,
        resetRefresh,
        photoAttempts,
        setPhotoAttempts,
      }}
    >
      {children}
    </RefreshContext.Provider>
  );
}

export function useRefresh() {
  const context = useContext(RefreshContext);
  return context;
}
