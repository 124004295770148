/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faCamera,
} from '@fortawesome/free-solid-svg-icons';
import Webcam from 'react-webcam';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import FormData from 'form-data';
import ButtonN from '../../../../components/ButtonN';
import { useSettings } from '../../../../hooks/settings';
import {
  Section,
  HeaderArea,
  CamStyled,
  Preview,
  ButtonPicture,
  ButtonPhoto,
  WhiteText,
  WhiteTitle,
  OverlayContainer,
  Container,
  Box,
  Corner1,
  Corner2,
  Corner3,
  Corner4,
} from './styles';
import api from '../../../../services/api';
import { useRefresh } from '../../../../hooks/refresh';
import eyesMask from '../../../../assets/eyes-mask.png';
import {
  ArrowContainer,
  ArrowDown, ArrowLeft, ArrowRight, ArrowUp, FaceContainer, FrontalFaceContainer,
} from '../../../Register/Steps/PF/Selfie/Cam/styles';
import { CheckpointService } from '../../../../services/endPointRegister/CheckpointService';

const Cam = () => {
  const {
    token, setStep, setPhotoAttempts, photoAttempts,
  } = useRefresh();

  const [image, setImage] = useState('');
  const webcamRef = React.useRef(null);
  const [photoData, setPhotoData] = useState({ photo: '' });
  const [loading, setLoading] = useState(false);
  const { settings, getEmailSupportMessage } = useSettings();
  const lastAttempt = photoAttempts === 1;
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setPhotoData({ ...photoData, photo: imageSrc });
  }, [webcamRef, photoData]);
  const [arrowOrders, setArrowOrders] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [CurrentArrow, setCurrentArrow] = useState(null);
  const [selfieUnique, setSelfieUnique] = React.useState(false);

  React.useEffect(() => {
    if (settings.name === 'nmpay' || settings.name === 'qesh') {
      const getCheckpoints = async () => {
        try {
          const { res } = await CheckpointService(settings.name);

          const hasSelfieUnique = res.response.find(
            (checkpoint) => checkpoint === 'SelfieUnique',
          );

          setSelfieUnique(hasSelfieUnique ? true : false);
        } catch (error) {
          console.log('Error getCheckpoints', error);
        }
      };

      getCheckpoints();
    }
  }, [settings.name]);

  useEffect(() => {
    const arrows = {
      FRONT: (
        <FrontalFaceContainer>
          <img
            style={{ width: '300px', height: '400px' }}
            src={eyesMask}
            alt="eyes-mask"
          />
        </FrontalFaceContainer>
      ),
      UP: (
        <FaceContainer>
          <ArrowUp>
            <FontAwesomeIcon icon={faArrowUp} style={{ color: '#42C1C7', fontSize: '60' }} />
          </ArrowUp>
        </FaceContainer>
      ),
      RIGHT: (
        <FaceContainer>
          <ArrowRight>
            <FontAwesomeIcon icon={faArrowRight} style={{ color: '#42C1C7', fontSize: '60' }} />
          </ArrowRight>
        </FaceContainer>
      ),
      DOWN: (
        <FaceContainer>
          <ArrowDown>
            <FontAwesomeIcon icon={faArrowDown} style={{ color: '#42C1C7', fontSize: '60' }} />
          </ArrowDown>
        </FaceContainer>
      ),
      LEFT: (
        <FaceContainer>
          <ArrowLeft>
            <FontAwesomeIcon icon={faArrowLeft} style={{ color: '#42C1C7', fontSize: '60' }} />
          </ArrowLeft>
        </FaceContainer>
      ),
    };

    const side = arrowOrders[currentIndex];
    const arrow = arrows[side];

    setCurrentArrow(arrow);
  }, [currentIndex, arrowOrders]);

  const videoConstraints = {
    width: 300,
    height: 400,
    facingMode: 'user',
  };

  useEffect(() => {
    const orders = [
      'FRONT',
      'UP',
      'RIGHT',
      'DOWN',
      'LEFT',
    ];
    setArrowOrders(orders);
  }, []);

  const handleSelfie = async () => {
    setLoading(true);
    const endUrl = (arrowOrders[currentIndex] === 'FRONT' ? 'SELFIE' : `SELFIE${arrowOrders[currentIndex].charAt(0)}`);
    const blob = await (await fetch(photoData.photo)).blob();
    const formData = new FormData();
    const file = new File([blob], 'selfie.png');
    formData.append('file', file);
    try {
      await api.post(`onboarding/documents/${endUrl}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      setImage('');
      if (selfieUnique && arrowOrders[currentIndex] === 'FRONT') {
        setStep('documents');
        return;
      }
      if (currentIndex >= arrowOrders.length - 1) {
        setStep('documents');
        return;
      }
      setCurrentIndex(currentIndex + 1);
    } catch (error) {
      setLoading(false);
      setImage('');
      setPhotoAttempts(error?.response?.data?.message?.attempts);
      if (
        error.response.data.message.message
        === 'Could not detect a face. Try another image. '
      ) {
        message.error(
          'Não foi possível detectar sua face. Tente novamente.',
          6,
        );
      } else if (
        error.response.data.message.message
        === 'Please try to avoid excessive light in the background. '
      ) {
        message.error(
          'Evite excesso de luz no ambiente. Tente novamente. ',
          6,
        );
      } else if (error.response.data.message.attempts < 1) {
        message.error(
          `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`,
          7,
        );
      } else {
        message.error(
          'Erro ao validar a imagem, gentileza envie outra foto.',
          7,
        );
      }
      setLoading(false);
      setImage('');
    }
  };

  return (
    <>
      {/* {!cameraActive && message.error('Por favor ative sua camera')} */}
      <Section>
        <CamStyled>
          <HeaderArea>
            {lastAttempt ? (
              <>
                <WhiteTitle>Parece que tivemos algum problema com a sua foto</WhiteTitle>
                <WhiteTitle>Você só tem mais <span style={{ color: '#e22012' }}>1 tentativa</span>!</WhiteTitle>
              </>
            ) : (
              <>
                <WhiteTitle>Hora da foto!</WhiteTitle>
                <WhiteText>
                  Para garantir que ninguém esteja se passando por você, precisamos que nos envie uma foto do
                  seu rosto. Você terá apenas <span style={{ color: '#e22012' }}>5 tentativas </span>
                  para tirar a foto, então leia bem e siga todas as instruções. Vamos lá?
                </WhiteText>
              </>
            )}
          </HeaderArea>

          <>
            {image === '' ? (
              <Box>
                <Container>
                  <OverlayContainer>
                    <Corner1 borderColor={lastAttempt && '#e22012'} />
                    <Corner2 borderColor={lastAttempt && '#e22012'} />
                    <Corner3 borderColor={lastAttempt && '#e22012'} />
                    <Corner4 borderColor={lastAttempt && '#e22012'} />
                    <ArrowContainer>
                      {CurrentArrow}
                    </ArrowContainer>
                  </OverlayContainer>
                  <Webcam
                    audio={false}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/png"
                    ref={webcamRef}
                    screenshotQuality={1}
                    imageSmoothing
                    mirrored
                    style={{ objectFit: 'cover' }}
                    width={videoConstraints.width}
                    height={videoConstraints.height}
                  />
                </Container>
              </Box>
            ) : (
              <Preview
                src={image}
                style={{ objectFit: 'auto' }}
                width={videoConstraints.width}
                height={videoConstraints.height}
              />
            )}
          </>

          {image !== '' ? (
            <ButtonPhoto>
              <ButtonN
                style={{ background: 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  setImage('');
                }}
                disabled={loading}
              >
                Tirar outra
              </ButtonN>
              <ButtonN
                onClick={() => {
                  handleSelfie();
                }}
                type="primary"
                loading={loading}
                icon={loading && <Spin indicator={<LoadingOutlined />} />}
              >
                {loading ? 'Enviando' : 'Ficou boa'}
              </ButtonN>
            </ButtonPhoto>
          ) : (
            <ButtonPicture
              onClick={(e) => {
                e.preventDefault();
                capture(() => {});
              }}
            >
              <FontAwesomeIcon icon={faCamera} size="2x" color="#ffff" />
            </ButtonPicture>
          )}
        </CamStyled>
      </Section>
    </>
  );
};

export default Cam;
