import axios from 'axios';

import * as faceapi from 'face-api.js';

const api = axios.create({
  baseURL: process.env.REACT_APP_BANK_URL || '',
});

// Load models and weights
export async function loadModels() {
  const MODEL_URL = `${process.env.PUBLIC_URL}/models`;
  await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
  await faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
  await faceapi.loadFaceRecognitionModel(MODEL_URL);
}

export async function getFullFaceDescription(blob, inputSize = 512) {
  // tiny_face_detector options
  const scoreThreshold = 0.5;
  const OPTION = new faceapi.TinyFaceDetectorOptions({
    inputSize,
    scoreThreshold,
  });
  const useTinyModel = true;

  // fetch image to api
  const img = await faceapi.fetchImage(blob);

  // detect all faces and generate full description from image
  // including landmark and descriptor of each face
  const fullDesc = await faceapi
    .detectAllFaces(img, OPTION)
    .withFaceLandmarks(useTinyModel)
    .withFaceDescriptors();
  return fullDesc;
}

export async function detectExpressions(cam) {
  // const faceapiOptions = new faceapi.SsdMobilenetv1Options({ minConfidenceFace });

  const result = await faceapi.detectSingleFace(cam).withFaceExpressions();

  console.log(result);
  if (typeof result !== 'undefined') {
    if (result.expressions.happy) {
      return 'angry';
    }
    if (result.expressions.angry) {
      return 'angry';
    }

    return 'dont_recognize';
  }
  return 'dont_recognize';
}

export default api;
