import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import LoginAntigo from '../pages/LoginAntigo';
import Login from '../pages/Login';
import Transaction from '../pages/Transaction';
import Charge from '../pages/Charge';
import ChargeBeta from '../pages/ChargeBeta';
import NewChargeBeta from '../pages/ChargeBeta/NewCharge';
import Deposit from '../pages/Deposit';
import Payment from '../pages/Payment';
import Register from '../pages/Register';
import RegisterAntigo from '../pages/RegisterAntigo';
import ForgotPassword from '../pages/ForgotPassword';
import Store from '../pages/Store';
import ResetUserPassword from '../pages/ResetUserPassword';
import PFUserAntigo from '../pages/RegisterAntigo/PFUser';
import PJUserAntigo from '../pages/RegisterAntigo/PJUser';
import Support from '../pages/Support';
import Invite from '../pages/Invite';
import AccountSelection from '../pages/AccountSelection';
import NewCharge from '../pages/Charge/NewCharge';
import ImportCharges from '../pages/Charge/ImportCharges';
import Rese4DigitPassword from '../pages/Reset4DigitPassword';
import Documents from '../pages/Documents';
import TokenLogin from '../pages/TokenLogin';
import Withdraw from '../pages/Withdraw';
import Settings from '../pages/Settings';
import EducationalPayments from '../pages/nmpay/EducationalPayments';
import DetachedPayment from '../pages/nmpay/EducationalPayments/StudentPage/DetachedPayment';
import NMImportCharges from '../pages/nmpay/EducationalPayments/ImportCharges';
import Partners from '../pages/nmpay/EducationalPayments/NmPage/Partners';
import CreatePartner from '../pages/nmpay/EducationalPayments/NmPage/Partners/CreatePartner';
import EditPartner from '../pages/nmpay/EducationalPayments/NmPage/Partners/EditPartner';
import Evibe from '../pages/vibe/Evibe';
import HomeWibbank from '../pages/HomeWibbank';
import NotifyInvitation from '../pages/NotifyInvitation';
import InviteAcepted from '../pages/NotifyInvitation/InviteAcepted';
import ChangeAccount from '../pages/ChangeAccount';
import Terms from '../pages/Terms';
import Pix from '../pages/Pix';
import Transfer from '../pages/Transfer';
import Tef from '../pages/Transfer/TEF';
import Ted from '../pages/Transfer/TED';
import RefreshData from '../pages/RefreshData';
import RefreshDataPJ from '../pages/RefreshDataPJ';
import Privacy from '../pages/Privacy';
import { useSettings } from '../hooks/settings';

function Routes() {
  const { settings } = useSettings();
  const isKexion = settings?.name === 'kexion';
  return (
    <Switch>
      <Route path={['/', '/login']} exact component={Login} />
      <Route path="/login-old" exact component={LoginAntigo} />
      <Route path="/login-old/:token" exact component={TokenLogin} />
      <Route path="/register/:step?" exact component={Register} isPrivate />
      <Route path="/refreshData" exact component={RefreshData} />
      <Route path="/refreshDataPJ" exact component={RefreshDataPJ} />
      <Route path="/change-account" exact component={ChangeAccount} isPrivate />
      <Route path="/notify-invitation" exact component={NotifyInvitation} isPrivate />
      <Route path="/notify-invitation/accept" exact component={InviteAcepted} isPrivate />
      <Route path="/register-old" exact component={RegisterAntigo} />
      <Route path="/register/pf/:document" exact component={PFUserAntigo} />
      <Route path="/register/pj/:document" exact component={PJUserAntigo} />
      <Route path="/documents" exact component={Documents} isPrivate />
      <Route exact path="/forgot/:token?" component={ForgotPassword} />
      <Route
        path="/password-recovery/:token"
        exact
        component={ResetUserPassword}
      />
      <Route path="/support" exact component={Support} />
      <Route path="/invite" exact component={Invite} />
      <Route path="/transaction" exact component={Transaction} isPrivate />
      <Route path="/transfer" exact component={Transfer} isPrivate />
      <Route path="/transfer/ted" exact component={Ted} isPrivate />
      <Route path="/transfer/tef" exact component={Tef} isPrivate />
      <Route path="/charges" exact component={Charge} isPrivate />
      <Route path="/charges/new" exact component={NewCharge} isPrivate />
      <Route path="/charges/import" exact component={ImportCharges} isPrivate />

      <Route path="/billet-beta" exact component={ChargeBeta} isPrivate />
      <Route
        path="/billet-beta/new"
        exact
        component={NewChargeBeta}
        isPrivate
      />

      {/* <Route path="/charges/billet" exact component={BilletCharge} isPrivate /> */}
      <Route path="/deposit" exact component={Deposit} isPrivate />
      <Route path="/pix" exact component={Pix} isPrivate />
      <Route path="/payment" exact component={Payment} isPrivate />
      <Route path="/settings" exact component={Settings} isPrivate />
      <Route path="/store" exact component={Store} isPrivate />
      <Route path="/withdraw" exact component={Withdraw} isPrivate />
      {isKexion || <Route path="/settings" exact component={Settings} isPrivate />}
      {isKexion || <Route path="/store" exact component={Store} isPrivate />}
      {isKexion || <Route path="/deposit" exact component={Deposit} isPrivate />}
      <Route path="/pix" exact component={Pix} isPrivate />
      <Route
        path="/account-selection"
        exact
        component={AccountSelection}
        isPrivate
      />
      <Route
        path="/reset-4digit"
        exact
        component={Rese4DigitPassword}
        isPrivate
      />
      <Route
        path="/educational-payments"
        exact
        component={EducationalPayments}
        isPrivate
      />
      <Route
        path="/educational-payments/detached"
        exact
        component={DetachedPayment}
        isPrivate
      />
      <Route
        path="/educational-payments/import-charges"
        exact
        component={NMImportCharges}
        isPrivate
      />
      <Route
        path="/educational-payments/partners"
        exact
        component={Partners}
        isPrivate
      />
      <Route
        path="/educational-payments/partners/create"
        exact
        component={CreatePartner}
        isPrivate
      />
      <Route
        path="/educational-payments/partners/:document"
        exact
        component={EditPartner}
        isPrivate
      />
      <Route path="/evibe" exact component={Evibe} isPrivate />
      <Route path="/homewib" exact component={HomeWibbank} isPrivate />
      <Route path="/terms" exact component={Terms} />
      <Route path="/privacy" exact component={Privacy} />
    </Switch>
  );
}

export default Routes;
